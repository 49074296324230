import React from "react";
import { Box, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";

const HeadingSection = ({ id, image, primaryHeading, secondaryHeading }) => {
  return (
    <>
      <Box id={id} bg="#F6F8F9" pt={20} pb={8}>
        <Box maxWidth="1160px" mx="auto" px={5}>
          <Text
            textStyle="feminine"
            fontSize={{ base: "32px", md: "60px" }}
            // fontWeight="bold"
            color="#2C7F4B"
          >
            {primaryHeading}
          </Text>
          {secondaryHeading ? (
            <Text
              textStyle="feminine"
              fontSize={{ base: "20px", md: "36px" }}
              // fontWeight="bold"
              color="#2C7F4B"
            >
              {secondaryHeading}
            </Text>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Box px={{ base: 5, md: 0 }}>
        <BackgroundImage Tag="section" {...image}>
          <Box w="100%" h="320px"></Box>
        </BackgroundImage>
      </Box>
    </>
  );
};

export default HeadingSection;

HeadingSection.propTypes = {
  primaryHeading: PropTypes.string.isRequired,
  secondaryHeading: PropTypes.string,
  image: PropTypes.node,
  id: PropTypes.string,
};
