/* EXAMPLE */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage } from "gatsby-plugin-image";
import { Box } from "@chakra-ui/react";

export default function Hero() {
  const { pizzaBanner } = useStaticQuery(query);
  return (
    <Box mt={{ base: 0, md: 20 }}>
      <BackgroundImage
        Tag="section"
        fluid={pizzaBanner?.childImageSharp.fluid}
        preserveStackingContext
      >
        <Box bgColor="rgba(0,0,0,0.6)" style={{ height: 500, width: "100%" }}>
          <Box
            h="100%"
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={10}
          >
            <Box maxWidth="500px">
              <StaticImage
                src="../../static/logoBanner.svg"
                placeholder="blurred"
                alt="logo banner"
              />
            </Box>
            <Box></Box>
          </Box>
        </Box>
      </BackgroundImage>
    </Box>
  );
}

const query = graphql`
  query {
    pizzaBanner: file(relativePath: { eq: "pizzaBanner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
