import React from "react";
import { Box, Grid, Text, List, ListItem, Link } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";

const CompanyInfo = () => {
  const { site } = useStaticQuery(query);
  const { street, streetNumber, zipCode, city, tel } = site.siteMetadata;
  return (
    <Box bgCOlor="#F6F8F9" pt={{ base: 8, md: 32 }} pb={{ base: 0, md: 60 }}>
      <Box maxWidth="1160px" mx="auto" color="#5A5A5A">
        <Box px={{ base: 4, md: 16 }}>
          <Text fontSize={{ base: "14px", md: "40px" }}>
            Treten Sie ein in die kulinarische Welt Italiens, gleich um die
            Ecke, in der {street} {streetNumber},{zipCode} in {city}.
          </Text>
        </Box>
        <Box
          px={{ base: 4, md: 16 }}
          py={{ base: 8, md: 12 }}
          mt={10}
          bgColor={{ base: "#353535", md: "#F6F8F9" }}
        >
          <Text
            id="footer-main"
            fontSize={{ base: "32px", md: "60px" }}
            fontStyle="feminine"
            // fontWeight="bold"
            color="#2C7F4B"
          >
            So finden Sie uns
          </Text>
        </Box>
        <Box w="100%" px={2} bgColor={{ base: "#353535", md: "#F6F8F9" }}>
          <Grid
            templateColumns="35% 1fr"
            gap={4}
            fontSize={{ base: "14px", md: "24", lg: "30px" }}
            color={{ base: "#A6ADB4", md: "#5A5A5A" }}
          >
            <Box pl={{ base: 4, md: 8, lg: 12 }}>Adresse:</Box>
            <Box>
              {street} {streetNumber}, {zipCode} {city}
            </Box>
            <Box pl={{ base: 4, md: 8, lg: 12 }}> Öffnungszeiten:</Box>
            <Box>
              <List spacing={3}>
                <ListItem>Montag: Ruhetag</ListItem>
                <ListItem>Dienstag - Freitag: 11.30-15.00 Uhr</ListItem>
              </List>
            </Box>
            <Box pl={{ base: 4, md: 8, lg: 12 }}>Telefon:</Box>
            <Box>
              <Link href={`tel:${tel[0]}`}>{tel[0]}</Link>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyInfo;

const query = graphql`
  query {
    site {
      siteMetadata {
        tel
        streetNumber
        street
        city
        zipCode
      }
    }
  }
`;
