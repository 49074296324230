import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../components/Hero";
import HeadingSection from "../components/HeadingSection";
import CompanyInfo from "../components/CompanyInfo";
import { Box, Text, Grid, UnorderedList, ListItem } from "@chakra-ui/react";
import ScrollToTop from "react-scroll-to-top";

const specializationList = [
  { name: "Vino" },
  { name: "Bruschetta" },
  { name: "Olio" },
  { name: "Pizza Cono" },
  { name: "Specialita" },
  { name: "Panino glutenfrei" },
  { name: "Formaggi" },
  { name: "Tingela" },
  { name: "Bibite" },
  { name: "Antipasti" },
  { name: "Espresso" },
  { name: "Mozarella" },
  { name: "Pasta" },
  { name: "Tiamisu" },
  { name: "Senza glutine" },
  { name: "Rofitterols" },
  { name: "Salse" },
  { name: "Gelati Speciali" },
];
const piccoliPiattiList = [
  {
    name: "Panino",
    price: "4,50",
    currency: "€",
  },
  {
    name: "Focciacia",
    price: "3,50",
    currency: "€",
  },
  {
    name: "Bruschetta",
    price: "5,00",
    currency: "€",
  },
  {
    name: "Figella",
    price: "3,50",
    currency: "€",
  },
  {
    name: "Panino Piccolo",
    subName: "(glutenfrei)",
    price: "3,80",
    currency: "€",
  },
  {
    name: "Pinsa",
    price: "4,00",
    currency: "€",
  },
  {
    name: "Caprese",
    price: "4,00",
    currency: "€",
  },
  {
    name: "Antipasto",
    price: "3,80",
    currency: "€",
  },
  {
    name: "Formaggi misti",
    price: "4,00",
    currency: "€",
  },
  {
    name: "Tiramisu",
    price: "2,50",
    currency: "€",
  },
  {
    name: "Rofiteroles",
    price: "2,50",
    currency: "€",
  },
];
const specialitaList = [
  {
    name: "Pizza",
    description: "",
    price: "3,00",
    currency: "€",
  },
  {
    name: "Panini Ciabatta",
    description: "Mit mozzarella, Rucola, Tomaten",
    price: "3,00",
    currency: "€",
  },
  {
    name: "Panini Ciabatta",
    description: "Mit Parma",
    price: "3,00",
    currency: "€",
  },
];

// markup
const IndexPage = () => {
  const { site, pastaRed, pasta, panini, mozzarella, pizza } =
    useStaticQuery(query);
  const pastaRedImage = getImage(pastaRed);
  const pastaImage = getImage(pasta);
  const paniniImage = getImage(panini);
  const mozzarellaImage = getImage(mozzarella);
  const pizzaImage = getImage(pizza);
  const pastaRedBgImage = convertToBgImage(pastaRedImage);
  const pastaBgImage = convertToBgImage(pastaImage);
  const paniniBgImage = convertToBgImage(paniniImage);
  const mozzarellaBgImage = convertToBgImage(mozzarellaImage);
  const pizzaBgImage = convertToBgImage(pizzaImage);

  const { person } = site.siteMetadata;
  return (
    <Layout>
      <SEO title="Italienische Spezialitäten" />
      <ScrollToTop
        style={{ padding: "5px", bottom: "20px" }}
        color="#DD413B"
        aria-label="scroll to top"
        smooth
        top={20}
      />
      <Hero />
      <Box my={20} maxWidth="1160px" mx="auto" px={5}>
        <Text
          textStyle="feminine"
          fontSize={{ base: "32px", md: "60px" }}
          mb={5}
          // fontWeight="bold"
          color="#2C7F4B"
        >
          Für Liebhaber der mediterranen Küche
        </Text>
        <Text fontSize={{ base: "14px", md: "25px" }}>
          Wählen Sie aus unserem umfangreichen Sortiment an Feinkost aus den
          verschiedensten Regionen Italiens. Von Schinken über Käse bis hin zu
          Obst und Gemüse ist alles dabei. Gerne beantworten wir Ihre Fragen
          persönlich in unserem Feinkostgeschäft. Ihre Familie{" "}
          {person[0].lastName}
        </Text>
      </Box>
      <HeadingSection
        id="mittagstisch"
        primaryHeading="Mittagstisch"
        image={pastaRedBgImage}
      />
      <Box bg="#ffffff" py={2} mx={{ base: 5, md: 0 }}>
        <Box maxWidth="1160px" mx="auto" px={4}>
          <Text fontSize={{ base: "14px", md: "25px" }} my={8}>
            Unsere leckeren Spezialitäten können am Tisch im Ambiente der
            Norderstraße genossen werden– bei einem köstlichen Wein oder einem
            duftenden Espresso. Wir freuen uns auf Sie!
          </Text>
        </Box>
      </Box>
      <HeadingSection
        primaryHeading="Unsere Spezialitäten"
        image={pastaBgImage}
      />
      <Box bg="#ffffff" py={16} mx={{ base: 5, md: 0 }}>
        <Box maxWidth="1160px" mx="auto" px={4}>
          <UnorderedList
            styleType="none"
            color="#5A5A5A"
            fontSize={{ base: "20px", md: "50px" }}
            // fontWeight="bold"
          >
            <ListItem>
              Pasta
              <UnorderedList
                styleType="none"
                fontSize={{ base: "16px", md: "36px" }}
              >
                <ListItem>
                  Mozzarella &amp; Käse
                  <UnorderedList styleType="none">
                    <ListItem>Antipasti &amp; Dessert</ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              Olio
              <UnorderedList
                styleType="none"
                fontSize={{ base: "16px", md: "36px" }}
              >
                <ListItem>Glutenfreie frische Pasta </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>Vino</ListItem>
            <ListItem>Getränke</ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <HeadingSection
        primaryHeading="Piccoli Piatti"
        secondaryHeading="Kleine Gerichte"
        image={paniniBgImage}
      />

      <Box bg="#ffffff" py={16} mx={{ base: 5, md: 0 }}>
        <Box maxWidth="1160px" mx="auto" px={4}>
          <Grid
            templateColumns="65% 1fr"
            gap={4}
            fontSize={{ base: "18px", lg: "40px" }}
            color="#5A5A5A"
          >
            {piccoliPiattiList.map((item, index) => (
              <React.Fragment key={index}>
                <Box pl={{ base: 4, md: 24 }}>
                  {item.name}{" "}
                  {item.subName ? (
                    <>
                      <br />
                      <Box as="span" fontSize={{ base: "16px", lg: "32px" }}>
                        {item.subName}
                      </Box>
                    </>
                  ) : null}
                </Box>
                <Box>
                  {item.price} {item.currency}
                </Box>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Box>
      <HeadingSection
        primaryHeading="Spezialitäten"
        image={mozzarellaBgImage}
      />
      <Box bg="#ffffff" py={16} mx={{ base: 5, md: 0 }}>
        <Box maxWidth="1160px" mx="auto" px={4}>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={4}
            fontSize={{ base: "18px", lg: "40px" }}
            color="#5A5A5A"
          >
            {specializationList.map((item, index) => (
              <React.Fragment key={index}>
                <Box pl={{ base: 4, md: 12 }}>{item.name}</Box>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </Box>
      <HeadingSection
        primaryHeading="Specialita &amp; Pasta"
        secondaryHeading="Mittagstisch zum Mitnehmen"
        image={pizzaBgImage}
      />
      <Box bg="#ffffff" py={16} mx={{ base: 5, md: 0 }}>
        <Box maxWidth="1160px" mx="auto" px={4}>
          <Box w="100%">
            <Grid
              templateColumns="70% 1fr"
              gap={4}
              fontSize={{ base: "18px", lg: "40px" }}
              color="#5A5A5A"
            >
              {specialitaList.map((item, index) => (
                <React.Fragment key={index}>
                  <Box>
                    {item.name}
                    <Text fontSize={{ base: "14px", md: "32px" }}>
                      {item.description}
                    </Text>
                  </Box>
                  <Box>
                    {item.price} {item.currency}
                  </Box>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <CompanyInfo />
    </Layout>
  );
};

export default IndexPage;

const query = graphql`
  query {
    site {
      siteMetadata {
        person {
          firstName
          lastName
        }
      }
    }
    pastaRed: file(relativePath: { eq: "pasta_red_wine.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    pasta: file(relativePath: { eq: "pasta.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    panini: file(relativePath: { eq: "panini.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    mozzarella: file(relativePath: { eq: "mozzarella.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    pizza: file(relativePath: { eq: "pizza.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
  }
`;
